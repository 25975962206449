<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-layout wrap justify-start>
            <v-flex text-left xs12 sm12 md6 px-3
              ><span style="font-size: 22px"> Style Partners</span>
            </v-flex>
            <v-flex text-right xs12 sm6 md4>
              <v-text-field
                dense
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-flex>
            <!-- <v-flex text-right xs12 sm6 md2>
                  <download-excel :data="json_data" :fields="json_fields">
                    <span style="font-size: 16px; cursor: pointer">
                      Download Excel</span
                    >
                  </download-excel>
                </v-flex> -->
          </v-layout>
          <v-layout wrap justify-end py-4>
            <v-flex sm12 md10>
              <v-layout wrap justify-end>
                <v-flex text-right xs12 sm6 md3>
                  <v-btn small outlined color="red" href="/pendingStylePartners"
                    >Pending Style Partners</v-btn
                  >
                </v-flex>
                <v-flex text-right xs12 sm6 md3 px-2>
                  <v-btn small outlined color="red" href="/removedStylePartners"
                    >Rejected Style Partners</v-btn
                  >
                </v-flex>
                <!-- <v-flex text-right xs12 sm6 md3>
                  <v-btn small outlined  color="red" href="/pendingProducts"
                    >Pending Product</v-btn
                  >
                </v-flex> -->
              </v-layout>
            </v-flex>
          </v-layout>
          <v-data-table
            :headers="headers"
            :items="user"
            :items-per-page="count"
            hide-default-footer
            class="elevation-1"
          >
            <!-- </template> -->
            <template v-slot:[`item.firstname`]="{ item }">
              <span>{{ item.firstname }}</span>
              <span> {{ item.lastname }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="sellerinfo(item._id)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="edit(item._id)">
                mdi-pencil
              </v-icon>
              <!-- <v-icon small @click="deleteItem(item._id)"> mdi-delete </v-icon> -->
            </template>
          </v-data-table>
          <div class="pt-2" v-if="pages > 1">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      search: "",
      ServerError: false,
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 12,
      headers: [
        { text: "Name", value: "firstname" },
        { text: "Username", value: "username" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        //   { text: "Organization", value: "organization" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      json_fields: {
        FirstName: "firstname",
        LastName: "lastname",
        Email: "email",
        Phone: "phone",
        Pincode: "pincode",
        Organization: "organization",
        BankName: "seller.bankname",
        Branch: "seller.branch",
        AccountNumber: "seller.accountnumber",
        IFSCcode: "seller.ifsccode",
        AccountHolder: "seller.accountholder",
        GSTID: "seller.gstid",
        OwnerName: "seller.ownername",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      user: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search() {
      this.currentPage = 1;
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/stylepartner/activeall",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          limit: this.limit,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.json_data = this.user;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    sellerinfo(sid) {
      this.$router.push("/stylePartnerDetails?id=" + sid);
    },
    edit(sid) {
      this.$router.push("/editStylePartners?id=" + sid);
    },
  },
};
</script>
  
  <style>
</style>